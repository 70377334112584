<template>
  <div>
    <!--    7c9dc7e027064c0fad065fa4972aa3b0-->
    <div ref="player" id="player-con"></div>
  </div>
</template>

<script>

export default {
  name: "Test",
  mounted() {
    this.a()
  },
  methods: {
    a() {
      console.log('asdasd')
      let player = new Aliplayer({
            "id": "player-con",
            // "source": "spm=a2c4g.11186623.2.17.329c476abvsoJm",

            vid: '7c9dc7e027064c0fad065fa4972aa3b0',
            accessKeyId: 'dd',
            securityToken: 'dd',
            accessKeySecret: 'dd',
            region: 'cn-shanghai',//eu-central-1,ap-southeast-1


            "width": "100%",
            "height": "500px",
            "autoplay": true,
            "isLive": false,
            "rePlay": true,
            "playsinline": true,
            "preload": true,
            "controlBarVisibility": "hover",
            "useH5Prism": true
          }, function (player) {
            console.log("The player is created");
          }
      );
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
